var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _c('div', {
    staticClass: "word-keep-all"
  }, [_c('h3', {
    staticClass: "tit tit--md line-height-15 primary--text mb-40 mb-lg-60"
  }, [_vm._v(" 안녕하십니까! "), _c('br'), _vm._v(" 위드-캔복지재단 홈페이지를 방문하신 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 여러분들을 환영합니다. ")]), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_c('h5', {
    staticClass: "tit tit--sm secondary--text mb-8 mb-lg-16"
  }, [_vm._v(" \"먼저, 사회복지 실천 전문법인으로 거듭나겠습니다.\" ")]), _c('p', {
    staticClass: "page-text line-height-15"
  }, [_vm._v(" 우리 사회복지계도 급변하고 있습니다. "), _c('br'), _vm._v(" 이에 우리 재단은 시대적 요구에 부응하는 전문적 복지사업 수행을 위해, 사회복지 각 분야에서 다년간의 경험을 보유한 분들로 구성된 이사진, 사회복지가치와 실행력을 겸비한 사회복지사들이 함께 그동안의 사업을 되돌아보고, 사회복지프로그램 연구, 개발을 통해 보다 전문적인 서비스와 프로그램을 제공하는 사회복지 실천 전문법인으로 거듭날 수 있도록 모든 임직원이 최선을 다하겠습니다. ")])]), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_c('h5', {
    staticClass: "tit tit--sm secondary--text mb-8 mb-lg-16"
  }, [_vm._v(" \"또한, 우리 모두가 행복한 사회를 만들기 위해 노력하겠습니다.\" ")]), _c('p', {
    staticClass: "page-text line-height-15 mb-4 mb-lg-8"
  }, [_vm._v(" 그동안 우리 재단은 다양한 전문사회복지사업을 통해 소외되고 어려웠던 이웃들에게 꿈과 희망을 심어주었고, 지역주민들로부터 신뢰받는 복지재단이 되기 위해 노력해 왔습니다. ")]), _c('p', {
    staticClass: "page-text line-height-15 mb-4 mb-lg-8"
  }, [_vm._v(" 앞으로도 한결같은 마음으로 항상 소외된 우리 이웃 가까이에서 희망이라는 따뜻한 불씨를 밝히겠습니다. ")]), _c('p', {
    staticClass: "page-text line-height-15"
  }, [_vm._v(" 나아가 시민과 다양한 전문가들의 고견에 귀를 기울여 우리나라 복지발전에 기여하는 새로운 시도와 방안을 모색하고 적극 추진해 나가겠습니다. ")])]), _c('p', {
    staticClass: "page-text grey-1--text line-height-15 font-weight-bold"
  }, [_vm._v(" 위드캔복지재단 임직원 일동 ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }