<template>
    <client-page>

        <sub-visual-intro />

        <section class="section">
            <v-container>

                <tab-intro />

                <div class="word-keep-all">
                    <h3 class="tit tit--md line-height-15 primary--text mb-40 mb-lg-60">
                        안녕하십니까! <br>
                        위드-캔복지재단 홈페이지를 방문하신 <br class="d-none d-lg-block">
                        여러분들을 환영합니다.
                    </h3>
                    <div class="mb-40 mb-lg-60">
                        <h5 class="tit tit--sm secondary--text mb-8 mb-lg-16">
                            "먼저, 사회복지 실천 전문법인으로 거듭나겠습니다."
                        </h5>
                        <p class="page-text line-height-15">
                            우리 사회복지계도 급변하고 있습니다. <br>
                            이에 우리 재단은 시대적 요구에 부응하는 전문적 복지사업 수행을 위해,
                            사회복지 각 분야에서 다년간의 경험을
                            보유한 분들로 구성된 이사진,
                            사회복지가치와 실행력을 겸비한 사회복지사들이 함께 그동안의 사업을 되돌아보고,
                            사회복지프로그램 연구, 개발을 통해 보다 전문적인 서비스와 프로그램을 제공하는
                            사회복지 실천 전문법인으로
                            거듭날 수 있도록 모든 임직원이 최선을 다하겠습니다.
                        </p>
                    </div> 
                    <div class="mb-40 mb-lg-60">
                        <h5 class="tit tit--sm secondary--text mb-8 mb-lg-16">
                            "또한, 우리 모두가 행복한 사회를 만들기 위해 노력하겠습니다."
                        </h5>
                        <p class="page-text line-height-15 mb-4 mb-lg-8">
                            그동안 우리 재단은 다양한 전문사회복지사업을 통해 소외되고 어려웠던 이웃들에게 꿈과 희망을 심어주었고,
                            지역주민들로부터 신뢰받는 복지재단이 되기 위해 노력해 왔습니다.
                        </p>
                        <p class="page-text line-height-15 mb-4 mb-lg-8">
                            앞으로도 한결같은 마음으로 항상 소외된 우리 이웃 가까이에서 희망이라는 따뜻한 불씨를 밝히겠습니다.
                        </p>
                        <p class="page-text line-height-15">
                            나아가 시민과 다양한 전문가들의 고견에 귀를 기울여 우리나라 복지발전에 기여하는 새로운 시도와 방안을 모색하고
                            적극 추진해 나가겠습니다.
                        </p>
                    </div> 
                    <p class="page-text grey-1--text line-height-15 font-weight-bold">
                        위드캔복지재단 임직원 일동
                    </p>
                </div>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
    },
};
</script>

<style lang="scss" scoped>
</style>